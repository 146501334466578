@mixin matero-customizer-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .customizer-panel {
    background-color: mat-color($background, dialog);
    color: mat-color($foreground, text);
  }

  .customizer-panel-overlay {
    &.opened {
      background-color: mat-color($foreground, divider);
    }
  }
}
