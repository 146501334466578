.panel .mat-dialog-container {
  border-radius: 5px;
  padding-left: 6px;
  padding-top: 6px;
  padding-right: 6px;
  background-color: $vodafone-red;
  max-width: 100vw;
  overflow: hidden;
}

mat-expansion-panel.mat-expansion-panel {
  background-color: $vodafone-red;
}
