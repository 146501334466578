.mat-list.no-wrap-text-list .mat-list-item .mat-line {
  word-wrap: break-word;
  white-space: pre-wrap;
}

//rende fli items dell'altezza minima necessaria e aggiunge margine
.mat-list-item.min-height-fix {
  height: initial !important;

  .mat-list-item-content {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
}
