@use 'sass:map';
@use '../core/theming/theming';

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .mtx-popover-panel {
    color: theming.get-color-from-palette($foreground, text);
    background-color: theming.get-color-from-palette($background, card);
  }

  [class*='mtx-popover'] {
    .mtx-popover-direction-arrow {
      &::before {
        border-color: theming.get-color-from-palette($foreground, divider);
      }

      &::after {
        border-color: theming.get-color-from-palette($background, card);
      }
    }
  }

  [class*='mtx-popover-below'],
  [class*='mtx-popover-above'] {
    .mtx-popover-direction-arrow {
      &::before,
      &::after {
        border-left-color: transparent;
        border-right-color: transparent;
      }
    }
  }

  [class*='mtx-popover-before'],
  [class*='mtx-popover-after'] {
    .mtx-popover-direction-arrow {
      &::before,
      &::after {
        border-top-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }
}

@mixin typography($config-or-theme) {}

@mixin _density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mtx-popover') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include _density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
