/* popup */

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px !important;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  margin-top: 200px !important
}

.leaflet-popup-content {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: -16px !important;
  line-height: 1.4;

}

.leaflet-popup-content p {
  margin: 18px 0 !important;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 0;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: rgba(66, 66, 66, 1) !important;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, .4);
}

.leaflet-mat-card.mat-card {
  // margin: 0;
  padding: 0;
  border-radius: 12px;
  max-width: 250px;
  min-width: 144px !important;
  max-height: none !important;
  width: fit-content;

  .mat-card-header {
    * {
      font-size: 12px;
      margin: 0;
      padding: 4px;
    }
  }

  .mat-card-content {
    * {
      font-size: 10px;
      margin: 0;
      padding: 0;
    }
  }


  .mat-icon {
    height: 24px !important;
    width: 24px !important;
    font-size: 24px !important;
  }

  .mat-list .mat-list-item {
    height: 100%; /* default is 72px */
    margin-bottom: 8px;
    margin-top: 8px;
  }
}

/* popup */
.leaflet-tooltip-pane .candidate-label {
  color: red;
  font-weight: bold;
  background: transparent;
  border: 0;
  box-shadow: none;
  font-size: 2em;
}
