.white-icon {
  color: white;
}

.white-icon svg {
  fill: white;
}


.green-icon {
  color: rgb(9, 255, 0) !important;
}

.green-icon svg {
  fill: rgb(9, 255, 0) !important;
}

