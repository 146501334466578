.mat-menu-panel.full-w-widget-mat-tab {
  max-width: none !important;
  width: 40vw;

  .mat-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}


@media only screen and (max-width: 600px) {
  .mat-menu-panel.full-w-widget-mat-tab {
    max-width: none !important;
    // width: calc(90vw - #{$sidenav-width}) !important;
    width: 69vw;


    .mat-menu-content {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
