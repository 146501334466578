.header-filter-input {
  .mat-form-field-wrapper {
    padding-bottom: 8px !important;
  }

  .mat-form-field-underline {
    bottom: 1px !important;
  }

  .mat-form-field-infix {
    width: auto !important;
  }
}
