@use 'sass:math';

@mixin make-grid-columns($i: 1, $list: '.col') {
  @each $breakpoint, $infix in $breakpoint-infixs {
    $infix: if($infix == '', '', '-#{$infix}');

    @if ($infix != '') {
      $list: '#{$list}, .col#{$infix}';
    }

    @for $i from 1 through $grid-columns {
      $list: '#{$list}, .col#{$infix}-#{$i}';
    }
  }

  #{$list} {
    position: relative;
    width: 100%;
    padding-left: ceil( math.div( $grid-gutter, 2 ) );
    padding-right: floor( math.div( $grid-gutter, 2 ) );
  }
}

@mixin loop-grid-columns($columns: $grid-columns, $breakpoint-infix: '') {
  $infix: if($breakpoint-infix == '', '', '-#{$breakpoint-infix}');

  .col#{$infix} {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  @for $i from 1 through $columns {
    .col#{$infix}-#{$i} {
      flex: 0 0 percentage( math.div( $i, $columns ) );
      max-width: percentage( math.div( $i, $columns ) );
    }

    .offset#{$infix}-#{$i} {
      margin-left: percentage( math.div( $i, $columns ) );
    }
  }
}
