/* You can add global styles to this file, and also import other style files */
@import 'app/theme/style';
@import 'styles/themes';
@import 'styles/custom';
@import 'styles/grid';
@import 'styles/helpers';
@import 'styles/plugins';

.mat-header-row, .mat-row {
  min-height: 30px;
  height: 30px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td.ellipsis {
  max-width: 250px;
}

.leaflet-control-measure h3 {
  color: black;
}

.leaflet-control-measure p {
  color: black;
}
.leaflet-popup-content-wrapper {
  background-color: white;
  color: white!important;
}

.leaflet-popup-content {
  margin: 13px 5px 13px 5px !important;
}


.green-semaphore {
  color: green;
}

.yellow-semaphore {
  color: yellow;
}

.red-semaphore {
  color: red;
}

.mat-tooltip {
  background-color: black!important;
}

.loading-rect {
  background: #ff0000;
  opacity: 0.3;
}

.pulse {
  animation: pulse 2.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.3;
  }
}

.has-aie::before {
  content: 'A';
  color: green;
}

.has-aie.has-nss::before {
  content: 'A  N';
  color: green;
}

.has-aie.has-ssi::before {
  content: 'A  S';
  color: green;
}

.has-aie.has-nss.has-ssi::before {
  content: 'A  N  S';
  color: green;
}

.has-nss::before {
  content: 'N';
  color: green;
}

.has-nss.has-ssi::before {
  content: 'N  S';
  color: green;
}

.has-ssi::before {
  content: 'S';
  color: green;
}

