@import 'variables';

:root {
  --filter-text-pop-up: 184px;
}

$filter-host-width: 250px;
$filter-text-height: 330px;
$filter-host-header-height: 32px;
$filter-text-pop-up: var(--filter-text-pop-up);

// option-list
.wnd-grid-option-list {
  padding-top: 0 !important;
  white-space: nowrap;

  .mat-list-item {
    height: 32px !important;
    font-size: 13px !important;

    .mat-list-item-content {
      padding: 0 8px !important;
    }
  }
}

.wnd-grid-option-list-textbox {
  .mat-form-field-wrapper {
    width: calc(100% - 16px);
    padding-bottom: 4px !important;

    .mat-form-field-flex {
      .mat-form-field-infix {
        width: 100%;

        .mat-input-element {
          font-size: 13px !important;
        }
      }
    }

    .mat-form-field-underline {
      bottom: 4px !important;
    }
  }
}

.wnd-grid-option-list-spinner {
  display: grid;
  place-items: center;
  margin-top: 2px;
}

.wnd-grid-option-list-wrapper {
  margin: 0;
  width: 100%;

  cdk-virtual-scroll-viewport {
    height: $filter-text-height - 106.52 - $filter-host-header-height;
    width: 100%;
  }
}

//filter-menu
.wnd-grid-mat-error {
  font-size: 13px !important;
}

.wnd-filter-menu-wrapper {
  width: 100%;
}

.wnd-grid-filter-type-wrapper {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      //height: 32px !important;

      .mat-form-field-infix {
        border-top: 0 !important;
        width: 100%;

        .mat-select-value {
          font-size: 13px !important;
        }
      }
    }
  }
}

.wnd-grid-input-wrapper {
  width: 100%;

  .mat-form-field-wrapper {
    padding-bottom: 8px !important;

    .mat-form-field-flex {
      .mat-form-field-infix {
        border-top: 0 !important;
        width: 100%;

        .mat-form-field-label {
          position: relative;
          top: 8px;
        }

        font-size: 13px !important;
      }
    }
  }

  .mat-form-field-underline {
    bottom: 4px !important;
  }
}

.filter-type-panel {
  position: absolute;
  top: 25px;
  left: 15.5px;
  font-size: 13px !important;
  min-width: calc(100% + 1px) !important;
  width: calc(100% + 1px) !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;

  .mat-option {
    height: 32px !important;
    padding: 0 6px;
  }
}

//text-filter

.wnd-grid-text-filter-disabled-trigger {
  pointer-events: none;
  cursor: default;
  opacity: .2;
}

.wnd-grid-text-filter-wrapper {
  height: $filter-text-height - 12px - $filter-host-header-height;
  //padding: 0 0 12px 12px;

  .wnd-grid-menu-trigger {
    margin-left: -12px;
    cursor: default !important;

    &:hover {
      background-color: lighten($dark-background, 10%);
    }

    p {
      font-size: 13px;
      margin-left: 12px;
    }

    mat-icon {
      margin-right: 8px;
    }
  }

  .wnd-grid-text-filter-divider {
    margin-left: -12px;
    height: 8px;
    border-bottom: 1px solid lighten($dark-background, 30%);
  }
}

mat-card.wnd-grid-filter-pop-up {
  //margin: 12px 16px 0 16px;
  margin: 0;
  width: $filter-text-pop-up;
  z-index: 48;
  padding: 12px;
}

//number-filter, boolean-filter, date-filter
.wnd-grid-other-filter-wrapper {
  //margin: 12px 16px 0 16px;
  margin: 0;
  width: 100%;
  //z-index: 48;
  padding: 0 12px 0 0;
}

//filter-host

.wnd-grid-filter-host-card {
  .wnd-grid-filter-host-header {
    height: $filter-host-header-height;
    background-color: lighten($dark-background, 20%);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-left: -12px;
    border-bottom: 2px solid $vodafone-red-light;
    cursor: pointer;
    margin-bottom: 8px;
  }

  width: $filter-host-width;
  padding: 0 0 12px 12px !important;
  margin: 0 !important;
}

//column-header

.wnd-grid-column-header-container {
  width: 100%;
  margin: 0;

  .divider {
    width: 100%;
    height: 1px;
    background-color: lighten($dark-background, 25%);
  }

  .wnd-grid-column-header-filter-ext {
    margin: 0;
    width: 100%;
    height: 50px;

    &:hover {
      background-color: lighten($dark-background, 20%);
    }

    .wnd-grid-column-header-filter {
      min-width: 80px;
      max-width: 300px;
      margin: 0;
      width: 100%;
      padding: 0 16px;
    }
  }

  .wnd-grid-column-diplayname-ext {
    margin: 0;
    width: 100%;
    height: 50px;
    cursor: pointer !important;

    .wnd-grid-column-clear {
      height: 100%;
      width: 38px;
      padding-right: 11px;
      padding-left: 11px;
      color: lighten($dark-background, 40%);

      &:hover {
        color: lighten($dark-background, 80%);
      }

      mat-icon {
        width: 16px;
        height: 16px;
        font-size: 16px;
      }
    }

    &:hover {
      background-color: lighten($dark-background, 20%);
    }

    .wnd-grid-column-diplayname {
      min-width: 65px;
      max-width: 300px;
      width: 100%;
      padding: 0 5px 0 15px;
      // height: 33.78px;

      mat-icon {
        font-size: 16px;
        padding-left: 12px;
        width: 28px;
        height: 16px;
      }

      p {
        font-size: 12px;
        font-weight: 600;
        font-stretch: extra-expanded;
      }
    }
  }
}

//wnd-grid

.wnd-grid-wrapper {

  &.full-height {
    height: 100% !important;
  }

  .wnd-grid-side-trigger-wrapper {
    width: 30px;
    background-color: lighten($dark-background, 4%) !important;
    margin: 0;
    border-radius: 0;
    padding: 48px 0 8px 0;
    border-bottom: 1px solid lighten($dark-background, 15%);
    border-top: 1px solid lighten($dark-background, 15%);
    border-right: 1px solid lighten($dark-background, 15%);

    .wnd-grid-side-trigger {
      padding: 16px 0;
      color: lighten($dark-background, 40%);
      cursor: pointer;

      &.active {
        //border-left: 2px solid red;
        background-color: lighten($dark-background, 7%) !important;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &:hover {
        color: lighten($dark-background, 80%);
      }

      mat-icon {
        font-size: 16px;
        margin-bottom: 8px;
        width: 16px;
        height: 16px;
      }

      p {
        writing-mode: vertical-rl;
        font-size: 12px;
        margin: 0;
      }
    }
  }

  .wnd-grid-drawer-container {
    &.side-nav {
      width: calc(100% - 30px);
    }

    &.no-side-nav {
      width: 100%;
    }

    .wnd-grid-sidenav {
      border-bottom: 1px solid lighten($dark-background, 15%);
      border-top: 1px solid lighten($dark-background, 15%);
      background-color: lighten($dark-background, 7%);

      .wnd-grid-sidenav-content {
        padding: 16px;
      }

      .wnd-grid-side-trigger-closer {
        width: 16px;
        color: lighten($dark-background, 40%);
        background-color: lighten($dark-background, 4%);
        cursor: pointer;

        &:hover {
          background-color: lighten($dark-background, 10%);
        }

        mat-icon {
          font-size: 30px;
          width: 30px;
          height: 30px;
        }
      }
    }

    .wnd-grid {
      height: 100%;

      .mtx-grid-main {
        background-color: lighten($dark-background, 7%);
      }

      table {
        thead {
          tr {
            .mat-header-cell {
              padding: 0 !important;

              .mat-header-cell-inner {
                display: inline !important;
              }
            }
          }
        }

        tbody {
          tr {
            // height: 40px;

            .mat-cell {
              font-size: 12px !important;
              word-break: break-all !important;
              padding: 0 16px !important;
            }
          }
        }
      }
    }

    .wnd-grid {
      &.wnd-grid-row-selectable {
        table {
          tbody {
            tr {
              cursor: pointer;

              &:hover {
                background-color: lighten($dark-background, 12%);
              }
            }
          }
        }
      }

      &.has-elastic-search {
        height: calc(100% - 66px);
      }
    }
  }
}

//other

mat-icon.wnd-grid-filter-icon.dark {
  padding-top: 3px;
  padding-left: 3px;
  font-size: 18px;
  color: $dark-background;
}

mat-icon.wnd-grid-filter-icon.light {
  font-size: 18px;
  color: lighten($dark-background, 40%);
  position: relative;
  top: 5px;
  cursor: pointer;
}

.wnd-grid-export-option {
  .mat-option-text {
    display: flex;
    align-items: center;
  }
}