@use 'sass:map';
@use '../core/theming/theming';
@use '../core/typography/typography';
@use '../core/typography/typography-utils';

$target-height: 22px;
$font-size: 12px;
$vertical-padding: ($target-height - $font-size) * .5;

$handset-target-height: 30px;
$handset-font-size: 14px;
$handset-vertical-padding: ($handset-target-height - $handset-font-size) * .5;

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $background: map.get($config, background);

  .mtx-tooltip {
    background: theming.get-color-from-palette($background, tooltip, .9);
  }
}

@mixin typography($config-or-theme) {
  $config: typography.private-typography-to-2014-config(
    theming.get-typography-config($config-or-theme)
  );

  .mtx-tooltip {
    font-family: typography-utils.font-family($config);
    font-size: $font-size;
    padding-top: $vertical-padding;
    padding-bottom: $vertical-padding;
  }

  .mtx-tooltip-handset {
    font-size: $handset-font-size;
    padding-top: $handset-vertical-padding;
    padding-bottom: $handset-vertical-padding;
  }
}

@mixin _density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mtx-tooltip') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography-default: typography.define-typography-config();
    $typography: theming.get-typography-config($theme, $typography-default);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include _density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
