/* width */
::-webkit-scrollbar {
  width: 15px;
  background: #424242;
}

/* Track */
::-webkit-scrollbar-track {
  background: #424242;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-corner {
  background: #424242;
}
