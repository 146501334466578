@import '@angular/material/theming';
@import 'app-theme';


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

//
// Light style
//
$primary: mat-palette($mat-red);
$accent: mat-palette($mat-green, A200, A100, A400);
$warn: mat-palette($mat-orange);
$theme: mat-light-theme($primary, $accent, $warn);

$config: mat-typography-config();

@include mat-core();

@include angular-material-theme($theme);
@include matero-admin-theme($theme);

//
// Demo purposes only
//
.theme-dark {
  $primary: mat-palette($mat-red);
  $accent: mat-palette($mat-green, A700, A100, A400);
  $warn: mat-palette($mat-orange);
  $theme: mat-dark-theme($primary, $accent, $warn);

  @include angular-material-theme($theme);
  @include matero-admin-theme($theme);
}

.mat-header-row, .mat-row {
  min-height: 30px;
}
