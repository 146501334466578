@use 'sass:map';
@use '../core/theming/theming';

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);

  .mtx-split {
    > .mtx-split-gutter {
      background-color: theming.get-color-from-palette($foreground, divider, .12);

      &:hover {
        background-color: theming.get-color-from-palette(map.get($config, primary), text);
      }

      &.mat-accent:hover {
        background-color: theming.get-color-from-palette(map.get($config, accent), text);
      }

      &.mat-warn:hover {
        background-color: theming.get-color-from-palette(map.get($config, warn), text);
      }
    }
  }
}

@mixin typography($config-or-theme) {}

@mixin _density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mtx-split') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include _density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
