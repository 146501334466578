.map-input {
  .mat-expansion-panel-body {
    top: 10px;
    padding: 0;
    background-color: transparent;
  }
}

.map-form-input {
  appearance: textfield;
  .mat-form-field-wrapper {
    padding-bottom: 2px !important;
  }

  .mat-form-field-underline {
    bottom: 1px !important;
  }

  .mat-form-field-infix {
    //padding-bottom: 10px;
  }
}

mat-expansion-panel-header.mat-expansion-panel-big-top-margin-indicator {
  height: 20px !important;

  .mat-expansion-indicator {
    margin-top: 20px;
  }
}
