.wnd-grid-toolbar {
  .mtx-grid-toolbar {
    background-color: mat-color($mat-gray, 800);
  }
}

mtx-grid.mtx-grid.wnd-grid {
  .mtx-grid-main {
    .mtx-grid-content {

      //overflow: auto;
      //max-height: 350px;
      .mat-table-container {
        table {
          .mat-row {
            background: #d3d3d3;

            .mat-cell {
              color: #080808;
            }

            &-odd {
              background: #E0E0E0;
            }
          }

          .mat-cell {
            border-bottom-color: #0000001f;

            mtx-grid-cell {
              font-size: 12px;
              // word-break: break-all;
              // color: black;
            }
          }
        }
      }
    }
  }
}