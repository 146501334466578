@mixin scrollbar-thin() {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 999px;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
  }

  &::-webkit-scrollbar-track {
    box-shadow: rgba(0, 0, 0, .2) 1px 1px 5px inset;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 20px;
    background-color: rgba(0, 0, 0, .2);
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  & {
    scrollbar-width: thin;
  }
}

@mixin scrollbar-none {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
