@use 'sass:math';
@import "variables";

// TODO: Grid row
.matero-row {
  margin-right: math.div(-$gutter, 2);
  margin-left: math.div(-$gutter, 2);
}

// TODO: Grid col
.matero-col {
  padding-right: math.div($gutter, 2);
  padding-left: math.div($gutter, 2);
}

.badge {
  @include badge();
}

.scrollbar-thin {
  @include scrollbar-thin();
}

.scrollbar-none {
  @include scrollbar-none();
}
